import { useEffect, useState } from 'react'
import { Loader, PageTitle } from 'jmt-styled-components'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { CongressSection } from './components/CongressSection'
import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { CongressTabs } from './components/CongressTabs'
import { useTranslation } from '../../utils/custom-hooks/useTranslation'
import { PageContainer } from '../../components/base/layouts/PageContainer'
import { fetchSummeryInfo } from '../../state/app-info/app-info-actions'

export const Congresses = ({ eventGroup, title }) => {
  const { t } = useTranslation()
  const {
    summery,
    fetching,
  } = useShallowEqualSelector((state) => state.appInfoReducer)
  let eventGroups = []
  if (eventGroup) {
    eventGroups = [eventGroup]
  } else {
    eventGroups = useShallowEqualSelector((state) => state.moduleConfigReducer.associationConfig)
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchSummeryInfo())
  }, [])

  const initialEventFilter = eventGroups.length ? eventGroups[0] : null
  const [eventFilter, setEventFilter] = useState(initialEventFilter)

  if (
    !fetching.summery
    || fetching.summery === 'fetching'
  ) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    )
  }

  if (!summery.PageEvents) {
    return (
      <PageContainer>
        <div>
          We have an error please try again later
        </div>
      </PageContainer>
    )
  }

  let filteredEvents = summery.PageEvents

  if (initialEventFilter) {
    filteredEvents = summery.PageEvents.filter((pageEvent) => {
      if (pageEvent.EventGroup) {
        return pageEvent.EventGroup.toUpperCase() === eventFilter.toUpperCase()
      }
      return false
    })
  }

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      {
        eventGroups.length > 1
          ? <CongressTabs setEventFilter={setEventFilter} eventFilter={eventFilter} eventGroups={eventGroups} />
          : null
      }
      {
        filteredEvents.map((event, idx) => (
          <CongressSection key={event.Identifier} event={event} isDefaultOpen={idx === 0} />
        ))
      }
    </PageContainer>
  )
}

Congresses.propTypes = {
  eventGroup: PropTypes.string,
  title: PropTypes.string.isRequired,
}

Congresses.defaultProps = {
  eventGroup: '',
}
