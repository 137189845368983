import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import {
  Link,
  useLocation,
} from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useTemplate } from '../../utils/custom-hooks/use-getters/useTemplate'

import { useShallowEqualSelector } from '../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../utils/custom-hooks/useTranslation'
import { LinksList } from '../LinksList'
import { LoggedInUserThumbnail } from './LoggedInUserThumbnail'

const Wrapper = styled.div`
  width: 100%;
  ${(p) => p.template}
`

const NavContainer = styled.nav`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  justify-content: flex-start;
  padding: 0 85px;
  ${(p) => p.template}

  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0;
    margin-top: 0;
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;
    transform: ${(p) => (!p.mobileNavVisible ? 'translateY(calc((60px + 8 * 50px) * -1))' : 'translateY(80px)')};
    transition: transform 1s;
  }
`

const NavItem = styled.span`
  padding: 0 25px;

  & > a {
    text-decoration: none;

    &:visited {
      color: #FFFFFF;
    }
    
    &:hover {
      text-decoration: none;
    }

    @media (max-width: 992px) {
      padding: 5px 0 5px 25px;
      display: block;
    }
  }

  ${(p) => p.isActive && css`  
    background-color: rgba(255, 255, 255, 0.15);
  `}

  &:last-child {
    margin-left: auto;
    margin-right: 15px;

    @media (max-width: 992px) {
      margin-right: 0;
      margin-left: 0;
      
    }
  }

  @media (max-width: 992px) {
    padding: 0;
  }
`
const LinkText = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.95rem;
  padding: 10px 0;
  line-height: 40px;
  color: #FFFFFF;

  @media (max-width: 992px) {
    text-transform: initial;
  }
`

const MobileNavItem = styled.div`
  display: none;

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    padding: 0 25px;
    ${(p) => p.padding && css`padding: ${p.padding};`}
    ${(p) => p.borderTop && css`border-top: 1px solid gray;`}
    ${(p) => p.borderBottom && css`border-bottom: 1px solid gray;`}

    & a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      ${(p) => p.template}
    }
  }
`

const calcActiveRoute = (path, currentPath) => {
  if (currentPath.indexOf(path) === 0) {
    if (path === '/') {
      return path === currentPath
    }
    return true
  }

  return false
}

export const Navbar = ({
  mobileNavVisible,
  metaLinks,
  onClick,
  token,
}) => {
  const { t } = useTranslation()
  const active = useLocation()

  const { User } = useShallowEqualSelector((storeState) => storeState.appInfoReducer.summery)

  const { props: templateProps } = useTemplate('Navbar')

  const { props: templatePropsMeta } = useTemplate('MetaLinks')

  const metaLinksTemplate = css`
    color: ${templatePropsMeta.mobileMetaLinkColor};

    &:visited {
      color: ${templatePropsMeta.mobileMetaLinkColor};
    }
  `

  const navContainerTemplate = css`
    background-color: ${templateProps.backgroundColor};
    color: ${templateProps.color};
  `

  const navWrapperTemplate = css`
    background-color: ${templateProps.backgroundColor};
  `

  const {
    header,
    pages,
  } = useShallowEqualSelector((store) => (
    store.moduleConfigReducer.associationConfig
  ))

  const ref = useRef(mobileNavVisible)

  useEffect(() => {
    ref.current = mobileNavVisible
  })

  useEffect(() => {
    const closeOnSelect = () => {
      if (ref.current) {
        onClick()
      }
    }
    document.addEventListener('click', closeOnSelect)

    return () => {
      document.removeEventListener('click', closeOnSelect)
    }
  }, [])

  const currentPath = active.pathname

  const { profileWebsite } = header

  return (
    <Wrapper template={navWrapperTemplate}>
      <NavContainer
        template={navContainerTemplate}
        mobileNavVisible={mobileNavVisible}
      >
        {
          token && pages.map((page) => {
            return (
              <NavItem
                key={page.name}
                isActive={calcActiveRoute(page.path, currentPath)}
              >
                <Link to={page.path}>
                  <LinkText>
                    {t(`Navbar-${page?.props?.title || page.name}_Page`)}
                  </LinkText>
                </Link>
              </NavItem>
            )
          })
        }
        <MobileNavItem template={metaLinksTemplate} padding="10px 25px" borderTop borderBottom>
          <LinksList links={metaLinks} />
        </MobileNavItem>
        <MobileNavItem padding="20px 25px">
          <LoggedInUserThumbnail />
        </MobileNavItem>
        <NavItem>
          <a
            style={!User ? { visibility: 'hidden' } : {}}
            href={`${window.location.origin}/auth/logout?returnUrl=${profileWebsite}`}
          >
            <LinkText>{t('Navbar-Logout')}</LinkText>
          </a>
        </NavItem>
      </NavContainer>
    </Wrapper>
  )
}

Navbar.propTypes = {
  token: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  mobileNavVisible: PropTypes.bool.isRequired,
  metaLinks: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
}

Navbar.defaultProps = {
  metaLinks: [],
}
