import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const CardWrapper = styled.div`
  ${(p) => p.feelRow && css`height: 100%;`}
  ${(p) => p.wrapperAdditionalStyles || ''}
`

const StyledCard = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: ${(p) => p.flexDir || 'column'};
  height: 100%;
  ${(p) => p.padding && css`padding: ${p.padding};`}
`

export const Card = ({
  wrapperAdditionalStyles,
  flexDir,
  children,
  feelRow,
  padding,
}) => (
  <CardWrapper wrapperAdditionalStyles={wrapperAdditionalStyles} feelRow={feelRow}>
    <StyledCard flexDir={flexDir} padding={padding}>
      {children}
    </StyledCard>
  </CardWrapper>
)

Card.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperAdditionalStyles: PropTypes.string,
  flexDir: PropTypes.string,
  feelRow: PropTypes.bool,
  padding: PropTypes.string,
}

Card.defaultProps = {
  flexDir: '',
  feelRow: false,
  padding: '',
  wrapperAdditionalStyles: '',
}
