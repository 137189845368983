import PropTypes from 'prop-types'
import { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const MetaNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const MetaNavItem = styled.li`
  display: inline-block;
  padding: 0 15px;
  ${(p) => p.isFirst && css`padding-left: 0;`}
  ${(p) => p.isLast && css`padding-right: 0;`}

  @media (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
    display: block;
  }

  & a {
    font-size: 14px;
    text-decoration: none;
  }
`

const Separator = styled.span`
  @media (max-width: 992px) {
    display: none;
  }
`

export const LinksList = ({
  links,
  separator,
  fontSize,
}) => (
  <MetaNavList>
    {links.map((item, idx) => (
      <Fragment key={item.url}>
        <MetaNavItem
          fontSize={fontSize}
          isFirst={idx === 0}
          isLast={idx === links.length - 1}
        >
          <a href={item.url} target={item.open || '_blank'} rel="noreferrer">{item.title}</a>
        </MetaNavItem>
        <Separator>
          { (idx < links.length - 1 && separator) && separator }
        </Separator>
      </Fragment>
    ))}
  </MetaNavList>
)

LinksList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      open: PropTypes.string,
    }),
  ).isRequired,
  separator: PropTypes.string,
  fontSize: PropTypes.string,
}

LinksList.defaultProps = {
  separator: '',
  fontSize: '',
}
