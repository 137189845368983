import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Collapsible from 'react-collapsible'
import { Section, Grid } from 'jmt-styled-components'

import { LinkBox } from '../../../components/dynamic-components/LinkBox'
import { CongressHeader } from './CongressHeader'

export const CongressSection = ({
  event,
  isDefaultOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(isDefaultOpen)
  }, [])
  return (
    <Section>
      <Collapsible
        onTriggerOpening={() => setIsOpen(true)}
        onTriggerClosing={() => setIsOpen(false)}
        open={isDefaultOpen}
        trigger={(
          <CongressHeader
            isOpen={isOpen}
            congressInfo={{
              name: event.Name,
              startDate: event.StartDateTime,
              endDate: event.EndDateTime,
              logoUrl: event.LogoUrl,
            }}
          />
        )}
      >
        <Grid>
          {
            event.LinkBoxes.map((box) => (
              <LinkBox
                key={box.Title}
                size={box.size}
                data={{
                  title: box.Title,
                  description: box.Description,
                  buttonTitle: box.ButtonTitle,
                  buttonIcon: box.ButtonIcon,
                  link: {
                    url: box.ButtonLink.Url,
                    target: box.ButtonLink.Target,
                  },
                  image: box.ImageUrl,
                  modulePermissions: box.ModulePermissions,
                }}
              />
            ))
          }
        </Grid>
      </Collapsible>
    </Section>
  )
}

CongressSection.propTypes = {
  isDefaultOpen: PropTypes.bool.isRequired,
  event: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    StartDateTime: PropTypes.string.isRequired,
    EndDateTime: PropTypes.string.isRequired,
    LogoUrl: PropTypes.string.isRequired,
    LinkBoxes: PropTypes.arrayOf(PropTypes.shape({
      Title: PropTypes.string,
      Description: PropTypes.string,
      ButtonTitle: PropTypes.string,
      ButtonIcon: PropTypes.string,
      ButtonLink: PropTypes.shape({
        Url: PropTypes.string,
        Target: PropTypes.string,
      }),
      ImageUrl: PropTypes.string,
    })).isRequired,
  }).isRequired,
}
