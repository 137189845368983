import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Card } from 'jmt-styled-components'

import { Icon } from 'jmt-styled-components'

const Container = styled.div`
  height: fit-content;
  margin-bottom: 30px;
`

const CardBody = styled.div`
  padding: 10px;
  display: flex;
  flex-grow: 1;
`

const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 25px;

  & svg {
    transform: ${(p) => (p.isOpen ? 'rotate(Deg)' : 'rotate(-90Deg)')};
    transform-origin: center;
    transition: transform 0.4s;
  }
`

const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  & > h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
`

const CardLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
`

// TODO: process dates with dayjs
const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
]

const returnDate = (startDate, endDate) => {
  const givenStartDate = new Date(startDate)
  const ddStart = String(givenStartDate.getDate()).padStart(2, '0')
  const mmStart = String(givenStartDate.getMonth())
  const yyyyStart = givenStartDate.getFullYear()
  const givenEndDate = new Date(endDate)
  const ddEnd = String(givenEndDate.getDate()).padStart(2, '0')
  const mmEnd = String(givenEndDate.getMonth())
  const yyyyEnd = givenEndDate.getFullYear()
  return `${ddStart} ${mmStart !== mmEnd ? `${monthNames[mmStart]} ` : ''}${yyyyEnd !== yyyyStart ? `${yyyyStart} ` : ''}- ${ddEnd} ${monthNames[mmEnd]} ${yyyyEnd}`
}

export const CongressHeader = ({ congressInfo, isOpen }) => (
  <Container>
    <Card>
      <CardBody>
        <CardIcon isOpen={isOpen}>
          <Icon icon="fa-chevron-down" size="lg" />
        </CardIcon>
        <CardTitle>
          <h3>{congressInfo.name}</h3>
          <p>
            {returnDate(congressInfo.startDate, congressInfo.endDate)}
          </p>
        </CardTitle>
        <CardLogo>
          <img height="50px" src={congressInfo.logoUrl} alt="" />
        </CardLogo>
      </CardBody>
    </Card>
  </Container>
)

CongressHeader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  congressInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
  }).isRequired,
}
