import {
  BrowserRouter as Router,
} from 'react-router-dom'

import { lazy } from 'react'
import { ConditionsChecker } from '../components/conditions-checker/ConditionsChecker'
import { ProfileModal } from '../components/profile-modal/ProfileModal'
import { RouterWrappers } from './router-setup/RouterWrappers'
import { useShallowEqualSelector } from '../utils/custom-hooks/useShallowEqualSelector'

const FreshChat = lazy(() => import('../components/FreshChat'))

export const RouterRoot = () => {
  const { extraFunctionalityFlags } = useShallowEqualSelector((state) => (
    state.moduleConfigReducer.associationConfig
  ))

  return (
    <Router>
      <RouterWrappers />
      <ConditionsChecker />
      <ProfileModal />
      {extraFunctionalityFlags.enableFreshChat && <FreshChat />}
    </Router>
  )
}
