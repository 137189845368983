import { useShallowEqualSelector } from '../useShallowEqualSelector'

export const useTemplate = (componentName) => useShallowEqualSelector((state) => {
  try {
    return (
      state.moduleConfigReducer.associationConfig.design.templates
        .find((temp) => temp.name === componentName)
    )
  } catch (e) {
    return {}
  }
})
