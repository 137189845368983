import { lazy, memo } from 'react'
import PropTypes from 'prop-types'

// Download fonts from here - https://en.bestfonts.pro/

const RobotoFontFace = lazy(() => import('./roboto/FontFace'))
const BarlowFontFace = lazy(() => import('./barlow/FontFace'))
const OpenSansFontFace = lazy(() => import('./open-sans/FontFace'))
const MyriadProFontFace = lazy(() => import('./myriad-pro/FontFace'))
const SourceSans3FontFace = lazy(() => import('./source-sans-3/FontFace'))

const fonts = {
  Roboto: RobotoFontFace,
  Barlow: BarlowFontFace,
  'Open Sans': OpenSansFontFace,
  'Myriad Pro': MyriadProFontFace,
  "'Source Sans 3'": SourceSans3FontFace,
}

export const FontsLoader = memo(({ fontFamily }) => {
  const FontFamily = fonts[fontFamily]
  if (!FontFamily) {
    return null
  }
  return <FontFamily />
})

FontsLoader.propTypes = {
  fontFamily: PropTypes.string,
}

FontsLoader.defaultProps = {
  fontFamily: '',
}
