import PropTypes from 'prop-types'
import { PageContainer as StyledPageContainer } from 'jmt-styled-components'

import { GoBackButton } from '../../GoBackButton'

export const PageContainer = ({ children }) => (
  <StyledPageContainer>
    <GoBackButton />
    {children}
  </StyledPageContainer>
)

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
}
