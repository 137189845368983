import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { css, Global, ThemeProvider } from '@emotion/react'
import { PageContainer } from 'jmt-styled-components'

import { FontsLoader } from './assets/fonts/FontsLoader'
import { RouterRoot } from './routes/RouterRoot'
import { fetchAssociationConfigAction, setModuleConfig } from './state/module-config/module-config-actions'
import { useShallowEqualSelector } from './utils/custom-hooks/useShallowEqualSelector'
import { InitialRouterLoad } from './routes/InitialRouterLoad'

const GlobalStyles = ({ theme }) => {
  const themeVars = Object.keys(theme).reduce((acc, key) => `${acc}--${key}: ${theme[key]};\n`, '')
  return (
    <Global styles={css`
    html {
      ${themeVars}
    }

    html, body, #root {
      margin: 0;
      padding: 0;
      font-family: var(--fontFamily), sans-serif;
      width: 100%;
      height: 100vh;
      min-height: 700px;
      background-color: #f2f2f2;
      line-height: 1.3;
    }

    #root {
      display: flex;
      flex-direction: column;
    }

    *, *::before, *::after {
      box-sizing: border-box;
    }

    html, body, div, span, h1, h2, h3, h4, h5, h6, ol, ul, li, p, a {
      margin: 0;
      padding: 0;
    }
  `}
    />
  )
}

GlobalStyles.propTypes = {
  theme: PropTypes.shape({}).isRequired,
}

export const AppInitializer = () => {
  const dispatch = useDispatch()

  const [hasConfig, setHasConfig] = useState(false)

  const { design = { theme: { } } } = useShallowEqualSelector((state) => (
    state.moduleConfigReducer.associationConfig
  ))

  useEffect(() => {
    const rootDiv = document.getElementById('root')

    dispatch(
      setModuleConfig({
        language: rootDiv.getAttribute('language'),
        oauthUrl: rootDiv.getAttribute('oauth'),
        apiUrl: rootDiv.getAttribute('apiurl'),
        identityUrl: rootDiv.getAttribute('identity'),
        short: rootDiv.getAttribute('short'),
        context: rootDiv.getAttribute('context'),
        instance: rootDiv.getAttribute('instance'),
      }),
    )
    dispatch(fetchAssociationConfigAction(() => setHasConfig(true)))
  }, [])

  if (!hasConfig) {
    return (
      <PageContainer>
        <InitialRouterLoad />
      </PageContainer>
    )
  }

  const { fontFamily } = design.theme

  return (
    <ThemeProvider theme={design.theme}>
      <GlobalStyles theme={design.theme} />
      <FontsLoader fontFamily={fontFamily} />
      <RouterRoot />
    </ThemeProvider>
  )
}
